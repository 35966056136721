<h3>{{ title }}</h3>
<p>Adicione os itens que você gostaria de devolver na tabela abaixo</p>

<div class="overflow-auto">
	<table class="table table-bordered table-striped" aria-describedby="tabela-itens-a-devolver">
		<thead>
			<tr>
				<th class="col-1" scope="col" *ngIf="isActive">Ações</th>
				<th class="col-2 text-center" scope="col">Modelo</th>
				<th class="col-4 text-center" scope="col">Nota fiscal de venda</th>
				<th class="col-2 text-center" scope="col">Tamanho</th>
				<th class="col-2 text-center" scope="col">Cor</th>
				<th class="col-2 text-center" scope="col">Quantidade</th>
				<th class="col-2 text-center" scope="col">Valor unitário</th>
				<th class="col-2 text-center" scope="col">Valor total</th>
				<th class="col-3 text-center" scope="col">Defeito</th>
				<th class="col-2 text-center" scope="col">Foto do defeito #1</th>
				<th class="col-2 text-center" scope="col">Foto do defeito #2</th>
				<th class="col-2 text-center" scope="col">Foto do defeito #3</th>
				<th class="col-2 text-center" scope="col" *ngIf="isProofOfDiscard">Foto peça sem etiqueta</th>
			</tr>
		</thead>
		<tbody>
			<ng-container *ngFor="let item of itemList">
				<tr *ngIf="isItemValid(item)">
					<td *ngIf="isActive">
						<div class="d-flex flex-column align-items-center justify-content-center gap-2">
							<div class="d-inline-block" [ngbTooltip]="hasProofOfDiscardSavedTooltip(item)">
								<button type="button" class="btn btn-secondary" (click)="editItem(item)" [disabled]="hasProofOfDiscardSaved(item)">
									<i class="bi bi-pencil"></i>
								</button>
							</div>
							<button type="button" class="btn btn-danger" (click)="removeItem(item)" *ngIf="isAddOrRemoveEnable">
								<i class="bi bi-trash"></i>
							</button>
						</div>
					</td>
					<td class="text-center">{{ createLabel(item, SalesReturnFieldIdType.Material) }}</td>
					<td class="text-center">{{ createLabel(item, SalesReturnFieldIdType.Invoice) }}</td>
					<td class="text-center">{{ createLabel(item, SalesReturnFieldIdType.Size) }}</td>
					<td class="text-center">{{ createLabel(item, SalesReturnFieldIdType.Color) }}</td>
					<td class="text-center">{{ createLabel(item, SalesReturnFieldIdType.Quantity) }}</td>
					<td class="text-center">{{ createLabel(item, SalesReturnFieldIdType.Value) | brl }}</td>
					<td class="text-center">{{ calculateTotalValueByItem(item) | brl }}</td>
					<td class="text-center">{{ createLabel(item, SalesReturnFieldIdType.Failure) }}</td>
					<td class="text-center">{{ createLabel(item, SalesReturnFieldIdType.Attachment1) }}</td>
					<td class="text-center">{{ createLabel(item, SalesReturnFieldIdType.Attachment2) }}</td>
					<td class="text-center">{{ createLabel(item, SalesReturnFieldIdType.Attachment3) }}</td>
					<td class="text-center" *ngIf="isProofOfDiscard">
						{{ createLabel(item, SalesReturnFieldIdType.AttachmentProofOfDiscard) }}
					</td>
				</tr>
			</ng-container>
			<tr *ngIf="!hasItems">
				<td colspan="12">Nenhum item para devolver</td>
			</tr>
		</tbody>
	</table>
	<div class="text-end" *ngIf="hasItems">
		<p class="fw-medium my-1">
			<span class="me-3">Quantidade Total: {{ getTotalQuantity() }}</span>
			<span>Valor total: {{ getTotalValue() | brl }}</span>
		</p>
	</div>
	<small *ngIf="isProofOfDiscard && isActive"> Clique no botão acima para editar e adicionar a imagem de comprovação do descarte do produto </small>
</div>

<button type="button" class="btn btn-secondary" (click)="addItem()" *ngIf="isAddOrRemoveEnable">Adicionar item</button>

<ng-template #maxItemCount let-modal>
	<div class="modal-header bg-warning">
		<h4 class="modal-title" id="modal-basic-title">Atenção!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">O número máximo de itens já foi atingido.</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="modal.dismiss()">Entendido</button>
	</div>
</ng-template>

<ng-template #itemModal let-modal>
	<form [formGroup]="itemForm" (ngSubmit)="saveItem()" novalidate>
		<div class="modal-header">
			<h4 class="modal-title" id="modal-basic-title">Item a devolver</h4>
			<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
		</div>
		<div class="modal-body">
			<div class="row g-3 needs-validation">
				<div class="col-24">
					<label for="material" class="form-label">Modelo *</label>
					<ng-select
						id="material"
						formControlName="material"
						[clearable]="false"
						(change)="loadInvoices()"
						[ngClass]="{ 'is-invalid': submitted && f.material.errors }">
						<ng-option selected disabled value="">Selecione</ng-option>
						<ng-container *ngFor="let item of fieldDataList.materials">
							<ng-option [value]="item.value">{{ item.value }} - {{ item.label }}</ng-option>
						</ng-container>
					</ng-select>
					<div class="invalid-feedback">Este campo é obrigatório</div>
				</div>

				<div class="col-24">
					<label for="invoice" class="form-label">Nota fiscal de venda *</label>
					<ng-select
						id="invoice"
						formControlName="invoice"
						[clearable]="false"
						(change)="loadSizes()"
						[ngClass]="{ 'is-invalid': submitted && f.invoice.errors }">
						<ng-option selected disabled value="">Selecione</ng-option>
						<ng-container *ngFor="let item of fieldDataList.invoices">
							<ng-option [value]="item.value">{{ item.value }} - {{ item.label }}</ng-option>
						</ng-container>
					</ng-select>
					<div class="invalid-feedback">Este campo é obrigatório</div>
				</div>

				<div class="col-24 col-lg-12">
					<label for="size" class="form-label">Tamanho *</label>
					<ng-select id="size" formControlName="size" [clearable]="false" (change)="loadColors()" [ngClass]="{ 'is-invalid': submitted && f.size.errors }">
						<ng-option selected disabled value="">Selecione</ng-option>
						<ng-container *ngFor="let item of fieldDataList.sizes">
							<ng-option [value]="item.value">{{ item.label }}</ng-option>
						</ng-container>
					</ng-select>
					<div class="invalid-feedback">Este campo é obrigatório</div>
				</div>

				<div class="col-24 col-lg-12">
					<label for="color" class="form-label">Cor *</label>
					<ng-select
						id="color"
						formControlName="color"
						[clearable]="false"
						(change)="loadValuesAndQuantities()"
						[ngClass]="{ 'is-invalid': submitted && f.color.errors }">
						<ng-option selected disabled value="">Selecione</ng-option>
						<ng-container *ngFor="let item of fieldDataList.colors">
							<ng-option [value]="item.value">{{ item.value }} - {{ item.label }}</ng-option>
						</ng-container>
					</ng-select>
					<div class="invalid-feedback">Este campo é obrigatório</div>
				</div>

				<div class="col-24 col-lg-12">
					<label for="quantity" class="form-label">Quantidade *</label>
					<ng-select
						id="quantity"
						[searchable]="true"
						[clearable]="false"
						formControlName="quantity"
						[ngClass]="{ 'is-invalid': submitted && f.quantity.errors }">
						<ng-option selected disabled value="">Selecione</ng-option>
						<ng-container *ngFor="let item of fieldDataList.quantities">
							<ng-option [value]="item.value">{{ item.label }}</ng-option>
						</ng-container>
					</ng-select>
					<div class="invalid-feedback">Este campo é obrigatório</div>
				</div>

				<div class="col-24 col-lg-12">
					<label for="value" class="form-label">Valor unitário *</label>
					<ng-select id="value" [clearable]="false" formControlName="value" [ngClass]="{ 'is-invalid': submitted && f.value.errors }">
						<ng-option selected disabled value="">Selecione</ng-option>
						<ng-container *ngFor="let item of fieldDataList.values">
							<ng-option [value]="item.value">{{ item.value | brl }}</ng-option>
						</ng-container>
					</ng-select>
					<p *ngIf="itemTotalValue" class="mt-2 mb-0">
						Valor total: {{ itemTotalValue | brl }}
					</p>
					<div class="invalid-feedback">Este campo é obrigatório</div>
				</div>

				<div class="col-24">
					<label for="failure" class="form-label">Defeito *</label>
					<ng-select
						id="failure"
						[clearable]="false"
						[searchable]="false"
						formControlName="failure"
						[ngClass]="{ 'is-invalid': submitted && f.failure.errors }">
						<ng-option selected disabled value="">Selecione</ng-option>
						<ng-container *ngFor="let item of fieldDataList.failures">
							<ng-option [value]="item.value">{{ item.value }}</ng-option>
						</ng-container>
					</ng-select>
					<div class="invalid-feedback">Este campo é obrigatório</div>
				</div>
				<div class="col-24">
					<label for="attachment1" class="form-label">Foto defeito #1 *</label>
					<div class="d-flex gap-2">
						<input
							id="attachment1"
							type="file"
							class="d-none"
							type="file"
							accept=".jpg, .jpeg, .png"
							(change)="onPictureChange($event, 'attachment1', SalesReturnFieldIdType.Attachment1)" />
						<input
							type="text"
							class="form-control"
							[value]="itemForm.value.attachment1?.nome"
							[ngClass]="{ 'is-invalid border-danger': attachmentSubmitted && f.attachment1.errors }"
							[disabled]="true" />
						<label for="attachment1" class="btn btn-secondary" [class.disabled]="!isAddOrRemoveEnable">Carregar</label>
						<button
							class="btn btn-danger"
							(click)="removeAttachment('attachment1', SalesReturnFieldIdType.Attachment1)"
							*ngIf="enableAttachmentRemove(itemForm.value.attachment1)">
							Remover
						</button>
					</div>
					<div class="invalid-feedback d-inline" *ngIf="attachmentSubmitted && f.attachment1.errors">
						<div *ngIf="f.attachment1.errors.required">Este campo é obrigatório</div>
						<div *ngIf="f.attachment1.errors.invalidFileType">O arquivo deve ser uma imagem jpg, jpeg ou png.</div>
						<div *ngIf="f.attachment1.errors.maxSizeExceeded">A foto excede o limite de 5 MB. Por favor, diminua ou escolha um arquivo menor.</div>
					</div>
				</div>
				<div class="col-24">
					<label for="attachment2" class="form-label">Foto defeito #2</label>
					<div class="d-flex gap-2">
						<input
							id="attachment2"
							type="file"
							class="d-none"
							accept=".jpg, .jpeg, .png"
							(change)="onPictureChange($event, 'attachment2', SalesReturnFieldIdType.Attachment2)" />
						<input type="text" class="form-control" [value]="itemForm.value.attachment2?.nome" [disabled]="true" />
						<label for="attachment2" class="btn btn-secondary" [class.disabled]="!isAddOrRemoveEnable">Carregar</label>
						<button
							class="btn btn-danger"
							(click)="removeAttachment('attachment2', SalesReturnFieldIdType.Attachment2)"
							*ngIf="enableAttachmentRemove(itemForm.value.attachment2)">
							Remover
						</button>
					</div>
				</div>
				<div class="col-24">
					<label for="attachment3" class="form-label">Foto defeito #3</label>
					<div class="d-flex gap-2">
						<input
							id="attachment3"
							type="file"
							class="d-none"
							accept=".jpg, .jpeg, .png"
							(change)="onPictureChange($event, 'attachment3', SalesReturnFieldIdType.Attachment3)" />
						<input type="text" class="form-control" [value]="itemForm.value.attachment3?.nome" [disabled]="true" />
						<label for="attachment3" class="btn btn-secondary" [class.disabled]="!isAddOrRemoveEnable">Carregar</label>
						<button
							class="btn btn-danger"
							(click)="removeAttachment('attachment3', SalesReturnFieldIdType.Attachment3)"
							*ngIf="enableAttachmentRemove(itemForm.value.attachment3)">
							Remover
						</button>
					</div>
				</div>
				<div class="col-24" *ngIf="isProofOfDiscard">
					<label for="discardAttachment" class="form-label">Foto da peça sem etiqueta *</label>
					<div class="d-flex gap-2">
						<input
							id="discardAttachment"
							type="file"
							class="d-none"
							accept=".jpg, .jpeg, .png"
							(change)="onPictureChange($event, 'discardAttachment', SalesReturnFieldIdType.AttachmentProofOfDiscard)" />
						<input
							type="text"
							class="form-control"
							[value]="itemForm.value.discardAttachment?.nome"
							[disabled]="true"
							[ngClass]="{ 'is-invalid border-danger': attachmentSubmitted && f.discardAttachment.errors }" />
						<label for="discardAttachment" class="btn btn-secondary">Carregar</label>
						<button
							class="btn btn-danger"
							(click)="removeAttachment('discardAttachment', SalesReturnFieldIdType.AttachmentProofOfDiscard)"
							*ngIf="itemForm.value.discardAttachment?.nome">
							Remover
						</button>
					</div>
					<div class="invalid-feedback d-inline" *ngIf="attachmentSubmitted && f.discardAttachment.errors">Este campo é obrigatório</div>
				</div>
			</div>
		</div>
		<div class="modal-footer">
			<button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancelar</button>
			<button type="submit" class="btn btn-primary" [disabled]="!isFormChanged">Salvar</button>
		</div>
	</form>
</ng-template>
