<form novalidate class="row needs-validation" [formGroup]="processForm" (ngSubmit)="saveProcess()">
	<div class="col-24 col-md-12 mb-2">
		<label for="reason" class="form-label">Motivo da devolução *</label>
		<select id="reason" class="form-select" formControlName="reason" (change)="changeReason()" [ngClass]="{ 'is-invalid': submitted && f.reason.errors }">
			<option selected disabled value="">Selecione</option>
			<ng-container *ngFor="let reason of reasonList">
				<option [value]="reason.value">{{ reason.label }}</option>
			</ng-container>
		</select>
		<div class="invalid-feedback">Este campo é obrigatório</div>
	</div>

	<div class="col-24 col-md-12 mb-2">
		<label for="requester" class="form-label">Solicitante *</label>
		<select id="requester" class="form-select" formControlName="requester" [ngClass]="{ 'is-invalid': submitted && f.requester.errors }">
			<option selected disabled value="">Selecione</option>
			<ng-container *ngFor="let requester of requesterList">
				<option [value]="requester.value">{{ requester.label }}</option>
			</ng-container>
		</select>
		<div class="invalid-feedback">Este campo é obrigatório</div>
	</div>

	<div class="col-24 mb-4">
		<label for="notes" class="form-label">Observações</label>
		<textarea class="form-control" id="notes" rows="3" formControlName="notes"></textarea>
	</div>

	<div *ngIf="f.reason.value && f.requester.value">
		<div class="col-24 mb-4" *ngIf="enableLogisticsReverse">
			<h3>Coleta da Mercadoria</h3>

			<div class="col-24 mb-4">
				<p>Realizar a devolução utilizando o código de rastreio fornecido abaixo *</p>
			</div>

			<div class="overflow-auto">
				<table class="table table-bordered table-striped" aria-describedby="tabela-logistica-reversa">
					<thead>
						<tr>
							<th class="col-3 text-center" scope="col">Tipo de Transporte</th>
							<th class="col-2 text-center" scope="col">Código de rastreio</th>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td class="text-center">{{ transportationType }}</td>
							<td class="text-center">{{ trackingCode }}</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>

		<div class="col-24 mb-4">
			<div [ngClass]="{ 'is-invalid': submitted && f.items.errors }">
				<shared-account-sales-return-items
					[fluxo]="fluxo"
					[etapa]="etapa"
					[isActive]="isActive"
					[itemList]="itemList"
					[attachmentList]="attachmentList"
					(itemAdded)="onItemAdded($event)"
					(itemEdited)="onItemEdited($event)"
					(itemRemoved)="onItemRemoved($event)" />
			</div>
			<div class="invalid-feedback alert alert-danger mt-2">
				<span *ngIf="f.items.errors?.required" class="d-block"> Este campo é obrigatório </span>
				<span *ngIf="!f.items.errors?.required && f.items.errors?.minLengthArray" class="d-block">
					É necessário informar ao menos 6 produtos para devolver
				</span>
			</div>
		</div>

		<div class="col-24 mb-4">
			<div [ngClass]="{ 'is-invalid': submitted && f.invoices.errors }">
				<shared-account-sales-return-invoices
					[fluxo]="fluxo"
					[etapa]="etapa"
					[isActive]="isActive"
					[invoiceList]="invoiceList"
					[attachmentList]="attachmentList"
					(invoiceAdded)="onInvoiceAdded($event)"
					(invoiceEdited)="onInvoiceEdited($event)"
					(invoiceRemoved)="onInvoiceRemoved($event)"
					*ngIf="enableInvoices" />
			</div>
			<div class="invalid-feedback alert alert-danger mt-2">Informe ao menos uma nota fiscal para devolver</div>
		</div>

		<div class="text-center mb-4" *ngIf="isActive">
			<button type="button" class="btn btn-outline-secondary me-2" (click)="redirect()">Cancelar</button>
			<div class="d-inline-block" [ngbTooltip]="isItemsInvalidForProofOfDiscardTooltip">
				<button class="btn btn-primary" type="submit" (click)="debugSaveProcess($event)" [disabled]="success || isItemsInvalidForProofOfDiscard">
					{{ saveButtonLabel }}
				</button>
			</div>
		</div>
	</div>
</form>

<ng-template #successModal let-modal>
	<div class="modal-header bg-success text-white">
		<h4 class="modal-title" id="modal-basic-title">Sucesso!</h4>
		<button type="button" class="btn-close" aria-label="Close" (click)="modal.dismiss()"></button>
	</div>
	<div class="modal-body">
		<form>
			<div class="mb-3">{{ successMessage }}</div>
		</form>
	</div>
	<div class="modal-footer">
		<button type="button" class="btn btn-primary" (click)="modal.dismiss()">Entendido</button>
	</div>
</ng-template>
